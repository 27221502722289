<template>
  <main class="main-content" style="background-color: white">
    <div class="signUP-admin">
      <div class="container-fluid h-100">
        <div
          class="row mx-0 p-0 w-100 align-items-center position-relative"
          style="min-height: 100vh"
        >
          <div class="col-12 mr-0 ml-5 d-flex justify-content-center w-100">
            <!-- <div class="col-6 m-0 p-0  ">
              <p class="title">log in</p>
            </div>
            <div class="col-6 m-0 p-0  ">
              <p class="title">log in</p>
            </div> -->
          </div>
          <!-- ----------------------------hide  smaller than md -->
          <div
            class="
              d-none d-md-block
              col-12 col-md-3
              m-0
              p-0
              boxlogin
              text-center
            "
          >
            <p class="title">log in</p>
            <!-- --------------------------- -->
            <div class="with-icon mb-3">
              <span class="la-user lar color-light"></span>
              <input
                v-model="loginformData.username"
                type="text"
                class="
                  form-control
                  ih-medium
                  ip-lightradius-xs
                  b-light
                  boxinput
                "
              />
            </div>
            <!-- ---------------------------- -->
            <div class="with-icon mb-3">
              <span class="color-light" v-html="iconunlock"></span>
              <input
                v-on:keyup.enter="loginFN"
                v-model="loginformData.password"
                type="password"
                class="
                  form-control
                  ih-medium
                  ip-lightradius-xs
                  b-light
                  boxinput
                "
              />
            </div>
            <!-- ----------------------------------------- -->
            <div class="row m-0 p-0 subtitle">
              <div class="col-6 m-0 p-0 text-left">
                <div class="form-check">
                  <input
                    class="form-check-input mycheckbox"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label class="form-check-label" for="defaultCheck1">
                    rememder me
                  </label>
                </div>
              </div>
              <!-- --------- -->
              <div class="col-6 m-0 p-0 text-right">
                <p type="button" class="" href="forget-password.html">
                  your password?
                </p>
              </div>
            </div>
            <!-- --------------------------- -->
            <div class="row-m-0 p-0">
              <div class="col-12 m-0 p-0">
                <button
                  :disabled="load == true"
                  type="button"
                  class="btn buttonlogin w-100 fontlogin"
                  @click="loginFN"
                >
                  log in
                  <div v-if="load == true" class="spinner-grow" role="status">
                    <!-- <span class="sr-only">Loading...</span> -->
                  </div>
                </button>
              </div>
            </div>
            <!-- ------------------------- -->
            <div class="row m-0 p-0">
              <div class="col-6 m-0 px-0 py-3 text-right memder">
                Not a memder?
              </div>
              <div class="col-6 m-0 py-3 pl-3 text-left sign">sign up</div>
            </div>
            <!-- ------------------------- -->
            <div class="row-m-0 p-0">
              <div class="col-12 m-0 px-0 py-3 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn buttonapplication fontapplication py-0 px-5"
                >
                  application
                </button>
              </div>
            </div>
            <!-- ------------------------- -->
          </div>

          <!-- ----------------------------hide  smaller than md -->
          <!-- ---------------------- img ------------------- -->
          <img
            class="w-100 eco-img-login-logo d-none d-md-block"
            src="/images/ecotech.png"
            alt=""
          />
          <!-- ---------------------------- for smaller than md -->
          <div class="d-sm-block d-md-none col-12 m-0 p-2 text-center card">
            <p class="title">log in</p>
            <!-- --------------------------- -->
            <div class="with-icon mb-3">
              <span class="la-user lar color-light"></span>
              <input
                v-model="loginformData.username"
                type="text"
                class="
                  form-control
                  ih-medium
                  ip-lightradius-xs
                  b-light
                  boxinput
                "
              />
            </div>
            <!-- ---------------------------- -->
            <div class="with-icon mb-3">
              <span class="color-light" v-html="iconunlock"></span>
              <input
                v-on:keyup.enter="loginFN"
                v-model="loginformData.password"
                type="password"
                class="
                  form-control
                  ih-medium
                  ip-lightradius-xs
                  b-light
                  boxinput
                "
              />
            </div>
            <!-- ----------------------------------------- -->
            <div class="row m-0 p-0 subtitle">
              <div class="col-6 m-0 p-0 text-left">
                <div class="form-check">
                  <input
                    class="form-check-input mycheckbox"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label class="form-check-label" for="defaultCheck1">
                    rememder me
                  </label>
                </div>
              </div>
              <!-- --------- -->
              <div class="col-6 m-0 p-0 text-right">
                <p type="button" class="" href="forget-password.html">
                  your password?
                </p>
              </div>
            </div>
            <!-- --------------------------- -->
            <div class="row-m-0 p-0">
              <div class="col-12 m-0 p-0">
                <button
                  :disabled="load == true"
                  type="button"
                  class="btn buttonlogin w-100 fontlogin"
                  @click="loginFN"
                >
                  log in
                  <div v-if="load == true" class="spinner-grow" role="status">
                    <!-- <span class="sr-only">Loading...</span> -->
                  </div>
                </button>
              </div>
            </div>
            <!-- ------------------------- -->
            <div class="row m-0 p-0">
              <div class="col-6 m-0 px-0 py-3 text-right memder">
                Not a memder?
              </div>
              <div class="col-6 m-0 py-3 pl-3 text-left sign">sign up</div>
            </div>
            <!-- ------------------------- -->
            <div class="row-m-0 p-0">
              <div class="col-12 m-0 px-0 py-3 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn buttonapplication fontapplication py-0 px-5"
                >
                  application
                </button>
              </div>
            </div>
            <!-- ------------------------- -->
          </div>
          <!-- ----------------------------for  smaller than md -->
          <!-- ---------------------- img ------------------- -->
          <img
            class="w-100 eco-img-login-logosm d-sm-block d-md-none"
            src="/images/ecotech.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- End: .signUP-admin  -->
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "LoginMain",

  data: function () {
    return {
      load: false,
      iconunlock: `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-unlock"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    ry="2"
                  ></rect>
                  <path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg
              >`,
      loginformData: {
        username: "",
        password: "",
      },
      passwordFieldType: "password",
    };
  },
  methods: {
    async loginFN() {
      try {
        this.load = true;
        let data = {
          PER_ID: this.loginformData.username,
          PER_PASS: this.loginformData.password,
        };

        let getAPI = await this.$API.call_API("post", "user/login", data);
        console.log(
          "🚀 ~ file: Login.vue ~ line 146 ~ loginFN ~ getAPI",
          getAPI
        );
        let getEncodeAccess_token = btoa(btoa(getAPI.data.access_token));
        localStorage.setItem("user", getEncodeAccess_token);
        this.$store.dispatch("auth/login");
        //
        this.$router.push("/welcome");
        this.load = false;
      } catch (error) {
        this.load = false;
        console.log("error");
        console.log(error);
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    cklogIn: async function () {
      if (this.$store.getters["auth/getloggedIn"]) {
        this.$router.push("/welcome");
      }
    },
    viewpass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
  mounted() {
    this.cklogIn();
  },
};
</script>

<style scoped>
.memder {
  color: #c4c4c4;
  font-weight: 700;
  font-size: 1.5em;
}
.sign {
  color: #008080;
  font-weight: 700;
  font-size: 1.5em;
}
.buttonlogin {
  background: #64748b;
  border-radius: 33px;
}
.fontlogin {
  font-weight: 700;
  font-size: 1.8em;
  color: #ffffff;
}
.buttonapplication {
  background: #929ab7;
  border-radius: 33px;
}
.fontapplication {
  font-weight: 300;
  font-size: 1.2em;
  color: #ffffff;
}
.mycheckbox {
  background: #f1f2f6 !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
}
.boxinput {
  background: #f1f2f6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.title {
  color: #000000;

  font-weight: 700;
  font-size: 4.6em;
}
.subtitle {
  color: #000000;
  font-weight: 700;
  font-size: 1.5em;
}
.boxlogin {
  position: absolute;
  top: 20%;
  left: 65%;
}
.eco-img-login-logo {
  position: absolute;
  top: 50%;
  left: 13%;
  transform: translate(-50%, -50%);
  max-width: 1022px;
}

.eco-img-bg {
  width: 140%;
  /* height: 80vh; */
}
.eco-img-login-logosm {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100px;
}
@media only screen and (max-width: 1600px) {
  .eco-img-bg {
    width: 140%;
  }
  .eco-img-login-logo {
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
    max-width: 400px;
  }
}
@media only screen and (max-width: 1300px) {
  .eco-img-bg {
    width: 140%;
  }
  .eco-img-login-logo {
    position: absolute;
    top: 50%;
    left: 18%;
    transform: translate(-50%, -50%);
    max-width: 900px;
  }
}
@media only screen and (max-width: 768px) {
  .eco-img-bg {
    width: 100%;
    height: auto;
  }
  .eco-img-login-logo {
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
    max-width: 400px;
  }
}

.card-body {
  border: 1px rgba(51, 51, 51, 0.25) solid;
  border-radius: 25px;
}
.btn-eco {
  background: rgb(47, 85, 164);
  background: linear-gradient(
    180deg,
    rgba(47, 85, 164, 1) 0%,
    rgba(53, 43, 108, 1) 100%
  );
  color: white;
}
.font-signin {
  font-size: 1rem;
}
.card-flex-login {
  height: 100%;
}
.card-login {
  max-width: 500px;
}
</style>