<template>
  <aside
    style="top: 62px"
    :class="` ${
      $store.getters['display/getAside'] ? 'sidebar collapsed' : 'sidebar'
    }`"
  >
    <div class="sidebar__menu-group">
      <ul class="sidebar_nav">
        <li class="menu-title">
          <span>Main menu</span>
        </li>
        <!-- <div  @click="onClickMenu('/welcome')">
                    <li v-if="1" >
                        <a   class="">
                            <span class="span-svg">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.4285 20.5716V12.0001H14.5714V20.5716M4.28564 9.42871L11.9999 3.42871L19.7142 9.42871V18.8573C19.7142 19.3119 19.5336 19.748 19.2121 20.0695C18.8906 20.391 18.4546 20.5716 17.9999 20.5716H5.99993C5.54527 20.5716 5.10924 20.391 4.78775 20.0695C4.46626 19.748 4.28564 19.3119 4.28564 18.8573V9.42871Z" stroke="url(#paint0_linear)" stroke-linecap="round" stroke-linejoin="round"/>
                              <defs>
                              <linearGradient id="paint0_linear" x1="11.9999" y1="3.42871" x2="11.9999" y2="20.5716" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#2F55A4"/>
                              <stop offset="1" stop-color="#352B6C"/>
                              </linearGradient>
                              </defs>
                              </svg>

                            </span>
                            <span class="menu-text">{{ t('welcome',{},{locale:getLocal}) }}</span>
                        </a>
                    </li>
                </div> -->

        <!-- <div @click="onClickMenu('ProjectPage')">
          <li v-if="1">
            <a class="">
              <span class="span-svg">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.4285 20.5716V12.0001H14.5714V20.5716M4.28564 9.42871L11.9999 3.42871L19.7142 9.42871V18.8573C19.7142 19.3119 19.5336 19.748 19.2121 20.0695C18.8906 20.391 18.4546 20.5716 17.9999 20.5716H5.99993C5.54527 20.5716 5.10924 20.391 4.78775 20.0695C4.46626 19.748 4.28564 19.3119 4.28564 18.8573V9.42871Z"
                    stroke="url(#paint0_linear)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="11.9999"
                      y1="3.42871"
                      x2="11.9999"
                      y2="20.5716"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2F55A4" />
                      <stop offset="1" stop-color="#352B6C" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
              <span
                v-if="$store.getters['display/getAside'] !== true"
                :class="
                  $route.name == 'ProjectPage' ? 'menu-text' : 'umenu-text'
                "
                >Project</span
              >
            </a>
          </li>
        </div>
        <div @click="onClickMenu('JobPage')">
          <li v-if="1">
            <a class="">
              <span class="span-svg">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4288 5.143H17.1431C17.5977 5.143 18.0338 5.32361 18.3553 5.6451C18.6767 5.96659 18.8574 6.40263 18.8574 6.85728V18.8573C18.8574 19.3119 18.6767 19.748 18.3553 20.0695C18.0338 20.391 17.5977 20.5716 17.1431 20.5716H6.85735C6.4027 20.5716 5.96666 20.391 5.64517 20.0695C5.32368 19.748 5.14307 19.3119 5.14307 18.8573V6.85728C5.14307 6.40263 5.32368 5.96659 5.64517 5.6451C5.96666 5.32361 6.4027 5.143 6.85735 5.143H8.57164M9.42878 3.42871H14.5716C15.045 3.42871 15.4288 3.81247 15.4288 4.28585V6.00014C15.4288 6.47353 15.045 6.85728 14.5716 6.85728H9.42878C8.95539 6.85728 8.57164 6.47353 8.57164 6.00014V4.28585C8.57164 3.81247 8.95539 3.42871 9.42878 3.42871Z"
                    stroke="#ADB4D2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.14595 10.1818V16H9.80504V11.5795H9.86186L11.68 16H12.3164L14.1346 11.5795H14.1914V16H14.8505V10.1818H14.0096L12.0323 15.0114H11.9641L9.98686 10.1818H9.14595Z"
                    fill="#ADB4D2"
                  />
                </svg>
              </span>
              <span
                v-if="$store.getters['display/getAside'] !== true"
                :class="$route.name == 'JobPage' ? 'menu-text' : 'umenu-text'"
                >Job</span
              >
            </a>
          </li>
        </div> -->
        <!-- --------------------------------- -->
        <div @click="onClickMenu('ShortUrlPage')">
          <li v-if="1">
            <a class="">
              <span class="span-svg">
                <i class="p-1 fas fa-qrcode"></i>
              </span>
              <span
                v-if="$store.getters['display/getAside'] !== true"
                :class="
                  $route.name == 'ShortUrlPage' ? 'menu-text' : 'umenu-text'
                "
                >ShortUrl</span
              >
            </a>
          </li>
        </div>
        <!-- --------------------------------- -->
        <!-- <div @click="onClickMenu('ShortUrlPage_BK_TEE')">
          <li v-if="1">
            <a class="">
              <span class="span-svg">
                <i class="p-1 fas fa-qrcode"></i>
              </span>
              <span
                v-if="$store.getters['display/getAside'] !== true"
                :class="
                  $route.name == 'ShortUrlPage_BK_TEE' ? 'menu-text' : 'umenu-text'
                "
                >ShortUrlPage_BK_TEE</span
              >
            </a>
          </li>
        </div> -->
        <!-- <div  @click="onClickMenu('/yearly')">
                    <li v-if="1" >
                        <a   class="">
                            <span class="span-svg">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.4288 5.143H17.1431C17.5977 5.143 18.0338 5.32361 18.3553 5.6451C18.6767 5.96659 18.8574 6.40263 18.8574 6.85728V18.8573C18.8574 19.3119 18.6767 19.748 18.3553 20.0695C18.0338 20.391 17.5977 20.5716 17.1431 20.5716H6.85735C6.4027 20.5716 5.96666 20.391 5.64517 20.0695C5.32368 19.748 5.14307 19.3119 5.14307 18.8573V6.85728C5.14307 6.40263 5.32368 5.96659 5.64517 5.6451C5.96666 5.32361 6.4027 5.143 6.85735 5.143H8.57164M9.42878 3.42871H14.5716C15.045 3.42871 15.4288 3.81247 15.4288 4.28585V6.00014C15.4288 6.47353 15.045 6.85728 14.5716 6.85728H9.42878C8.95539 6.85728 8.57164 6.47353 8.57164 6.00014V4.28585C8.57164 3.81247 8.95539 3.42871 9.42878 3.42871Z" stroke="#ADB4D2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M9.54439 10.1818L11.6467 13.6023V16H12.3512V13.6023L14.4535 10.1818H13.6467L12.033 12.8977H11.9648L10.3512 10.1818H9.54439Z" fill="#ADB4D2"/>
                              </svg>

                            </span>
                            <span class="menu-text">Yearly Report</span>
                        </a>
                    </li>
                </div>
                <div  @click="onClickMenu('/cleaning')">
                    <li v-if="1" >
                        <a   class="">
                            <span class="span-svg">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.4288 5.143H17.1431C17.5977 5.143 18.0338 5.32361 18.3553 5.6451C18.6767 5.96659 18.8574 6.40263 18.8574 6.85728V18.8573C18.8574 19.3119 18.6767 19.748 18.3553 20.0695C18.0338 20.391 17.5977 20.5716 17.1431 20.5716H6.85735C6.4027 20.5716 5.96666 20.391 5.64517 20.0695C5.32368 19.748 5.14307 19.3119 5.14307 18.8573V6.85728C5.14307 6.40263 5.32368 5.96659 5.64517 5.6451C5.96666 5.32361 6.4027 5.143 6.85735 5.143H8.57164M9.42878 3.42871H14.5716C15.045 3.42871 15.4288 3.81247 15.4288 4.28585V6.00014C15.4288 6.47353 15.045 6.85728 14.5716 6.85728H9.42878C8.95539 6.85728 8.57164 6.47353 8.57164 6.00014V4.28585C8.57164 3.81247 8.95539 3.42871 9.42878 3.42871Z" stroke="#ADB4D2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M14.476 11.9998C14.2601 10.8066 13.3055 10.1021 12.1351 10.1021C10.6464 10.1021 9.56689 11.2498 9.56689 13.0907C9.56689 14.9316 10.6464 16.0793 12.1351 16.0793C13.3055 16.0793 14.2601 15.3748 14.476 14.1816H13.7714C13.601 14.9884 12.9078 15.4202 12.1351 15.4202C11.0783 15.4202 10.2487 14.6021 10.2487 13.0907C10.2487 11.5793 11.0783 10.7611 12.1351 10.7611C12.9078 10.7611 13.601 11.193 13.7714 11.9998H14.476Z" fill="#ADB4D2"/>
                              </svg>

                            </span>
                            <span class="menu-text">Cleaning Report</span>
                        </a>
                    </li>
                </div>
                <div  @click="onClickMenu('/welcome')">
                    <li v-if="1" >
                        <a   class="">
                            <span class="span-svg">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.4288 5.143H17.1431C17.5977 5.143 18.0338 5.32361 18.3553 5.6451C18.6767 5.96659 18.8574 6.40263 18.8574 6.85728V18.8573C18.8574 19.3119 18.6767 19.748 18.3553 20.0695C18.0338 20.391 17.5977 20.5716 17.1431 20.5716H6.85735C6.4027 20.5716 5.96666 20.391 5.64517 20.0695C5.32368 19.748 5.14307 19.3119 5.14307 18.8573V6.85728C5.14307 6.40263 5.32368 5.96659 5.64517 5.6451C5.96666 5.32361 6.4027 5.143 6.85735 5.143H8.57164M9.42878 3.42871H14.5716C15.045 3.42871 15.4288 3.81247 15.4288 4.28585V6.00014C15.4288 6.47353 15.045 6.85728 14.5716 6.85728H9.42878C8.95539 6.85728 8.57164 6.47353 8.57164 6.00014V4.28585C8.57164 3.81247 8.95539 3.42871 9.42878 3.42871Z" stroke="#ADB4D2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M14.476 11.9998C14.2601 10.8066 13.3055 10.1021 12.1351 10.1021C10.6464 10.1021 9.56689 11.2498 9.56689 13.0907C9.56689 14.9316 10.6464 16.0793 12.1351 16.0793C13.3055 16.0793 14.2601 15.3748 14.476 14.1816H13.7714C13.601 14.9884 12.9078 15.4202 12.1351 15.4202C11.0783 15.4202 10.2487 14.6021 10.2487 13.0907C10.2487 11.5793 11.0783 10.7611 12.1351 10.7611C12.9078 10.7611 13.601 11.193 13.7714 11.9998H14.476Z" fill="#ADB4D2"/>
                              </svg>
                            </span>
                            <span class="menu-text">O&MReport</span>
                        </a>
                    </li>
                </div> -->
      </ul>
    </div>
  </aside>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "Aside",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  methods: {
    onClickMenu(name) {
      // this.$router.push(link).catch(err => {});
      this.$router.push({ name: name });
    },
  },
  mounted() {
    this.selectedMenu = this.menus.findIndex(
      (menu) => menu[2] == this.$route.path
    );
  },
  data() {
    return {
      selectedMenu: 0,

      menus: [
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar nav-icon"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>`,
          "Dashboard",
          "/dashboard",
          1,
        ],
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-plus nav-icon"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>`,
          "Customer",
          "/customer",
          // this.$store.getters['auth/getUser'].PERMISSION.scheduler_access
          1,
        ],
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder nav-icon"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>`,
          " List Bank",
          "/bank",
          1,
        ],
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder nav-icon"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>`,
          "Loan Agreement",
          "/loanagreement",
          // this.$store.getters['auth/getUser'].PERMISSION.scheduler_access
          1,
        ],
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder nav-icon"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>`,
          "Loan Transaction",
          "/loantransaction",
          // this.$store.getters['auth/getUser'].PERMISSION.scheduler_access
          1,
        ],
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder nav-icon"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>`,
          "Loan Document Master",
          "/loandocumentmaster",
          1,
        ],
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder nav-icon"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>`,
          "Loan Payment",
          "/loanpayment",
          1,
        ],
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-plus nav-icon"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>`,
          "User",
          "/user",
          1,
        ],
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder nav-icon"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>`,
          "Main Role Access",
          "/roleaccess",
          1,
        ],
        [
          `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder nav-icon"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>`,
          "Loan Document Bank List",
          "/loandocumentbanklist",
          1,
        ],
      ],
    };
  },
  computed: {
    getLocal: function () {
      return this.$store.getters["display/getLocal"];
    },
  },
  watch: {
    // $route(to, from) {
    //   this.selectedMenu = this.menus.findIndex(menu => menu[2] == to.path);
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-text {
  color: #2f55a4;
  font-weight: 500;
  padding-left: 5px;
}
.umenu-text {
  color: #adb4d2;
  font-weight: 500;
  padding-left: 10px;
}
.span-svg {
  margin-bottom: 5px;
}
</style>
