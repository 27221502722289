import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import store from '../store/index'
import * as main from '../services/main.service';
import * as constant from '../services/constant';
const routes = [
  { path: '/', redirect: '/ShortUrl' },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
   
  {
    path: '/ShortUrl',
    name: 'ShortUrlPage',
    meta: { requiresAuth: true  },
    component: () => import(/* webpackChunkName: "ShortUrlPage.vue" */'../views/ShortUrlPage.vue')
  },
  
   
    // -------------------------ต้นทำ app------------------


  {
    path: '/:pathMatch(.*)*',
    redirect: "/"
  }
]
//ส่ง jwt ไป vetify ทุกครั้งก่อนเข้า page เพื่อให่มั่นใจว่า kry ยังไม่หมดอายุหรือโดนปลอมแปลงถ้าไม่ผ่าน ให้ลบ และ rediract ไป login
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let getUser = store.getters['auth/getUser']
    //console.log(getUser)
    if(!getUser){
      store.dispatch('auth/NotFoundUser')
 
      next({
        path: '/login'
      })
      return
    }else{

    }
  }
  // if (to.matched.some(record => record.meta.requiresPlant)) {
  //   let GET_plantId = store.getters['localstorage/GET_plantId']
  //  // console.log(GET_plantId)
  //   if(!GET_plantId){
  //     alert("กรุณาเลือก Plant ก่อนครับ")
  //     next({
  //       path: '/welcome'
  //     })
  //     return
  //   }else{

  //   }
  // }
  
  
  // if (to.matched.some(record => record.meta.requiresAdmin)) {
  //   let getUser = store.getters['auth/getUser']
  //   console.log(getUser)
  //   if(getUser.ROLE !=='Admin'){
  //     next({
  //       path: '/welcome'
  //     })
  //     return
  //   }else{

  //   }
      
  // }

  next() // make sure to always call next()!
  
})
export default router
