<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/login">About</router-link>
  </div> -->
  <div>
  <Navbor v-if="$store.getters['auth/getloggedIn']" />
  <main class="main-content">
    <Aside v-if="$store.getters['auth/getloggedIn']"/>
     <router-view />
  </main>
  </div>
</template>
<script>
// @ is an alias to /src
import Navbor from '@/components/Navbor.vue'
import Aside from '@/components/Aside.vue'
// const io = require("socket.io-client");

export default {
  name: 'Home',
  components: {
    Navbor,
    Aside
  },
  data: function () {
    return {
      socket:null
    }
  },
  mounted() {
    // this.reloadAuto()
    // this.setSocket()
  },
  methods: {
    reloadAuto(){
      setInterval(function(){
         location.reload();
      },3600000);
    },
    async setSocket(){
      let _this = this ;
      // let socket = io.connect('http://localhost:3001');
      this.$socket.on('connect',(data)=> {
        if(this.GET_plantId){
          this.$socket.emit('plant-join',this.GET_plantId);
        }else{
          this.$socket.emit('plant-join','Not Found Plant');
        }
          
      });
      this.$socket.on('plant-data',(dataSocket)=> {     
          if(dataSocket.data){
            let getPlant = this.GET_plantId;
            for(let e of dataSocket.data){
              if(e.site_id==getPlant){
                this.$store.dispatch('service/SET_socketSetReload');
              }
            }
          }
          // {
          //   code: 1,
          //   message: 'success',
          //   data: [
          //     TextRow {
          //       id: 2127,
          //       name: 'ENERGY',
          //       site_id: 2255250,
          //       status: 'U',
          //       created_at: 2021-09-02T16:53:13.000Z,
          //       updated_at: 2021-09-05T13:16:44.000Z,
          //       inverter_company_id: 3
          //     }
          //   ]
          // }
          
      });
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    GET_inverterId:function(){
      return this.$store.getters['localstorage/GET_inverterId']
    },
    GET_socketSetReload:function(){
      return this.$store.getters['service/GET_socketSetReload']
    },
  },
  watch: {
    GET_plantId: function (val) {
      this.$socket.emit('plant-join',this.GET_plantId);
    },
    GET_socketSetReload: function (val) {
      console.log("reload in watch")
    },

  }
}
</script>

<style lang="scss">

*{
  font-size: clamp(12px, 4vw, 16px) ;
  font-family: 'Inter', sans-serif;
}
a{
  cursor: pointer;
}
.font-Roboto {
  font-family: 'Roboto', sans-serif !important;
}
.font-Inter {
  font-family: 'Inter', sans-serif;
}

.alias {cursor: alias;}
.all-scroll {cursor: all-scroll;}
.auto {cursor: auto;}
.cell {cursor: cell;}
.context-menu {cursor: context-menu;}
.col-resize {cursor: col-resize;}
.copy {cursor: copy;}
.crosshair {cursor: crosshair;}
.default {cursor: default;}
.e-resize {cursor: e-resize;}
.ew-resize {cursor: ew-resize;}
.grab {cursor: -webkit-grab; cursor: grab;}
.grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
.help {cursor: help;}
.move {cursor: move;}
.n-resize {cursor: n-resize;}
.ne-resize {cursor: ne-resize;}
.nesw-resize {cursor: nesw-resize;}
.ns-resize {cursor: ns-resize;}
.nw-resize {cursor: nw-resize;}
.nwse-resize {cursor: nwse-resize;}
.no-drop {cursor: no-drop;}
.none {cursor: none;}
.not-allowed {cursor: not-allowed;}
.pointer {cursor: pointer;}
.progress {cursor: progress;}
.row-resize {cursor: row-resize;}
.s-resize {cursor: s-resize;}
.se-resize {cursor: se-resize;}
.sw-resize {cursor: sw-resize;}
.text {cursor: text;}
.w-resize {cursor: w-resize;}
.wait {cursor: wait;}
.zoom-in {cursor: zoom-in;}
.zoom-out {cursor: zoom-out;}
.card{
  margin:0px;
}
.bg-red{
  background-color: #FF4D4F !important;
}
.bg-green{
  background-color: #20C997 !important;
}
.bg-blue{
  background-color: #2C99FF !important;
}
.font-blue{
  color: #2C99FF !important;
}
.font-red{
  color: #FF4D4F !important;
}
.font-green{
  color: #20C997 !important;
}
.font-color-main-sec{
  color:#2F55A4!important;
}
.font-color-main{
  color:#272B41!important;
}
.title-card{
  color:#272B41!important;
  font-weight: 500 !important;
  margin:0px;
}
a{
  cursor: pointer;
}
.show-date{
  color: #929AB7;
  font-size: 0.7rem;
}
</style>
